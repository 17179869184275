import { captureUnderscoreErrorException } from '@sentry/nextjs';
import type { GetStaticPropsResult, InferGetStaticPropsType, NextPageContext } from 'next/types';

import { Logger } from '@core/logger';
import { ETagLogger } from '@core/logger/type';
import { EHttpStatusCode } from '@core/type';

import { ViewBadRequest, ViewInternalError, ViewNotFound } from '../view';

type ErrorPageProps = {
  statusCode: number;
};

const ErrorPage = ({ statusCode }: InferGetStaticPropsType<typeof getStaticProps>) => {
  const getErrorPage = (code: number): JSX.Element => {
    switch (code as EHttpStatusCode) {
      case EHttpStatusCode.BAD_REQUEST:
        return <ViewBadRequest />;
      case EHttpStatusCode.NOT_FOUND:
        return <ViewNotFound />;
      case EHttpStatusCode.INTERNAL_SERVER_ERROR:
        return <ViewInternalError />;
      default:
        return <ViewBadRequest />;
    }
  };

  return <>{getErrorPage(statusCode)}</>;
};

export const getStaticProps = async (
  ctx: NextPageContext,
): Promise<GetStaticPropsResult<ErrorPageProps>> => {
  const statusCode = ctx?.res?.statusCode || ctx?.err?.statusCode || EHttpStatusCode.NOT_FOUND;

  Logger.setTags({ [ETagLogger.STATUS_CODE]: statusCode as EHttpStatusCode });
  await captureUnderscoreErrorException(ctx);
  Logger.setTags({ [ETagLogger.STATUS_CODE]: null });

  return {
    props: { statusCode },
  };
};

export default ErrorPage;
