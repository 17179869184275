import useTranslation from 'next-translate/useTranslation';

import { Translate } from '@core/constant';

import { ErrorDefaultView } from './ErrorDefaultView';

const ERROR_CODE = 500;

export const ViewInternalError = () => {
  const { t } = useTranslation(Translate.page.INTERNAL_ERROR);

  return <ErrorDefaultView error={ERROR_CODE} title={t('title')} subtitle={t('subtitle')} />;
};
