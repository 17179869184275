import useTranslation from 'next-translate/useTranslation';

import { Box, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { EActionAttribute, EColor, ETypographyVariant } from '@core/type';

export const ViewBadRequest = () => {
  const { t } = useTranslation(Translate.page.BAD_REQUEST);

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
      <Typography
        as={EActionAttribute.H1}
        fontStyle="italic"
        variant={ETypographyVariant.H2}
        color={EColor.PRIMARY}
      >
        {t('title')}
      </Typography>
    </Box>
  );
};
